import React from "react"
import { Link } from "gatsby"
import Band from "../components/band"

const Home = () => (
  <Band>
    <div className="landing-page">
      <h1>Welcome To CHPDC Answers. Please select your plan below:</h1>

      <ul className="link">
        <li>
          <Link to="/CHPDC">CLICK HERE</Link>&nbsp;
          for information on the cyberattack on CareFirst BlueCross BlueShield Community Health Plan District of Columbia (CHPDC), formerly Trusted Health Plan,
        </li>
        <li>
          <Link to="/THP">CLICK HERE</Link>&nbsp;
          for information on the cyberattack on Trusted Health Plans, Inc, (Trusted Health Plan Michigan and Harbor Health).
        </li>
      </ul>
    </div>
  </Band>
)

export default Home
