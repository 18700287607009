import React from "react"
import PropTypes from "prop-types"

const Band = ({ children }) => {
  return (
    <div className="cf-band bg-white">
      <div className="cf-container band-md">
        {children}
      </div>
    </div>
  )
}

Band.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Band